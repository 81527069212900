import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "./Config";
import { setError } from "./ErrorSlice";
import { errorFunction } from "../hooks/ErrorFunction";

const initState = {
  records: [],
  loading: false,
  error: false,
  complete: false,
  reloadAuth: false,
};

export const getAllCourse = createAsyncThunk(
  "course/ getAllCourse",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.get("/coures?limit=50000");
      return response.data;
    } catch (error) {
      // dispatch(
      //   setError({
      //     type: "error",
      //     message: "حدث خطاء ما في جلب كورساتي",
      //     location: "كورساتي",
      //   })
      // );
      return rejectWithValue(error.message);
    }
  }
);

export const deleteCourse = createAsyncThunk(
  "course/deleteCourse",
  async (id, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    try {
      await axiosInstance.delete(`/coures/${id}`);
      return id;
    } catch (error) {
      dispatch(
        setError({
          type: "error",
          message: "حدث خطاء ما في حذف درس",
          location: "كورساتي",
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

export const createCourse = createAsyncThunk(
  "course/createCourse",
  async (logData, { rejectWithValue, dispatch }) => {
    try {
      if (!logData.lacture || logData.lacture === "") {
        dispatch(
          setError({
            type: "error",
            message: "لا يوجد معرف للمحاضرة",
            location: "كورساتي",
          })
        );
        return rejectWithValue("لا يوجد معرف للمحاضرة");
      }
      const response = await axiosInstance.post("/coures", logData);
      dispatch(
        setError({
          type: "complete",
          message: "تم الشراء بنجاح",
          location: "كورساتي",
        })
      );
      return response.data;
    } catch (error) {
      dispatch(
        setError({
          type: "error",
          message: errorFunction(error, "حدث خطاء ما في شراء الكورس"),
          location: "كورساتي",
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const editCourse = createAsyncThunk(
  "course/editCourse",
  async ([id, formData], { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.put(`/coures/${id}`, formData);
      return response.data;
    } catch (error) {
      dispatch(
        setError({
          type: "error",
          message: "حدث خطاء ما في تعديل كورساتي",
          location: "كورساتي",
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const CountWatch = createAsyncThunk(
  "course/CountWatch",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`/coures/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const courseSlice = createSlice({
  name: "course",
  initialState: initState,
  reducers: {
    resetCourseData(state) {
      state.error = false;
      state.complete = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // getAllCourse
      .addCase(getAllCourse.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.complete = false;
        // state.reloadAuth = true;
      })
      .addCase(getAllCourse.fulfilled, (state, action) => {
        state.loading = false;
        state.records = action.payload;
        state.complete = false;
        // state.reloadAuth = false;
      })
      .addCase(getAllCourse.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to fetch coupons. Please try again.";
        state.complete = false;
        //  state.reloadAuth = false;
      })
      // deleteCourse
      .addCase(deleteCourse.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.complete = false;
      })
      .addCase(deleteCourse.fulfilled, (state, action) => {
        state.loading = false;
        state.records.data = state.records.data.filter(
          (e) => e._id !== action.payload
        );
        state.complete = true;
      })
      .addCase(deleteCourse.rejected, (state, action) => {
        state.loading = false;
        state.error = `Error: ${action.payload}`;
        state.complete = false;
      })
      // createCourse
      .addCase(createCourse.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.complete = false;
      })
      .addCase(createCourse.fulfilled, (state, action) => {
        state.loading = false;
        state.records = action.payload;
        state.complete = true;
      })
      .addCase(createCourse.rejected, (state, action) => {
        state.loading = false;
        state.error = `${action.payload}`;
        state.complete = false;
      })
      //  editCourse
      .addCase(editCourse.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.complete = false;
      })
      .addCase(editCourse.fulfilled, (state, action) => {
        state.loading = false;
        state.records.data = state.records.data.map((item) =>
          item?._id === action.payload.data?._id ? action.payload : item
        );
        state.complete = true;
      })
      .addCase(editCourse.rejected, (state, action) => {
        state.loading = false;
        state.error = `Error: ${action.payload}`;
        state.complete = false;
      })
      //  CountWatch
      .addCase(CountWatch.pending, (state) => {
        state.loading = false;
        state.error = null;
        state.complete = false;
      })
      .addCase(CountWatch.fulfilled, (state, action) => {
        state.loading = false;
        state.complete = true;
      })
      .addCase(CountWatch.rejected, (state, action) => {
        state.loading = false;
        state.error = `Error: ${action.payload}`;
        state.complete = false;
      });
  },
});
export const { resetCourseData } = courseSlice.actions;
export default courseSlice.reducer;
