import React, { useState } from "react";
import css from "./TeacherClasses.module.css";
import { Link, useParams } from "react-router-dom";
import BtnBuy from "../Buttons/BtnBuy/BtnBuy";
import { MdRemoveRedEye } from "react-icons/md";
import scroll from "react-scroll";
import { useSelector } from "react-redux";

export default function TeacherClasses({ data }) {
  const {records} = useSelector((state) => state.me)
  const [sectionFromClass, setSectionFromClass] = useState([]);
  const [classNameAA, setClassNameAA] = useState("");
  const { id } = useParams();
  const A = scroll.Link;

  

    const handleExpiryTime = (expires) => {
      let date = new Date(expires);
      let formattedDate = date.toLocaleDateString("ar-EG");
      return formattedDate;
    };


  const handelClassId = (id) => {
    const sectionFilter = data.section.filter((section) => {
      return section.class?._id === id;
    });

    const classNameFilter = data.classes.filter((e) => {
      return e?._id === id;
    });
    setSectionFromClass(sectionFilter);
    setClassNameAA(classNameFilter[0].name);
  };

  return (
    <>
      <div className="py-5">
        <h3
          style={{ textShadow: "var(--dark-color) 0px 0px 10px" }}
          className="text-center py-3 px-2 "
        >
          فصولي الدراسيه
        </h3>
        <div className="row text-center">
          {data?.classes?.map((item, i) => (
            <div
              key={i}
              className="col-lg-4 col-md-6 col-sm-12 mb-4 align-items-center justify-content-center"
            >
              <div className={css.cardForm}>
                <img
                  src={item.image}
                  className={css.cardItemBg}
                  alt="Card"
                  loading="lazy"
                />
                <div className={css.buttons}>
                  <h5 className={css.cardTitle}>
                    {" "}
                    {item?.title || item?.name}
                  </h5>
                  <A to="sections" smooth={true} duration={500}>
                    <button
                      onClick={() => handelClassId(item?._id)}
                      className={`btn my-2 ${css.button}`}
                    >
                      اضغط هنا للمشاهده
                    </button>
                  </A>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div id="sections">
          {classNameAA && (
            <h3
              style={{ textShadow: "var(--dark-color) 0px 0px 10px" }}
              className="text-center py-3 px-2 "
            >
              {`  الابواب الخاصة بال ${classNameAA}`}
            </h3>
          )}
          <div className="row  align-items-start ">
            {sectionFromClass.map((item, i) => (
              <div
                key={i}
                className="col-lg-4 col-md-6 col-sm-12 mb-4 d-flex align-items-center justify-content-center"
              >
                <div className={css.cardForm}>
                  <div className={css.cardItem}>
                    <img
                      src={item.image}
                      className={css.cardItemBg}
                      alt="Card"
                      loading="lazy"
                    />
                    <div className={css.price}>
                      <span>{`${item.price} جنيه`}</span>
                    </div>
                  </div>
                  <div className={`${css.cardFormInner} text-center`}>
                    <h3 className={`${css.sectionTitle} py-2 `}>
                      {item?.title || item?.name}
                    </h3>
                    <div className={`${css.cardDescription} py-1 `}>
                      <p>{item?.description}</p>
                    </div>
                    <div className="d-flex align-items-center  justify-content-around ">
                      <Link
                        to={`/teacher-profile/${id}/${item._id}`}
                        className={`btn  ${css.buttonSection} ${css.button}`}
                      >
                        اضغط للمشاهده{" "}
                        <MdRemoveRedEye className="fs-6 text-light" />
                      </Link>
                      {records.role === "admin" ||
                      records.role === "manager" ||
                      records.role === "teacher" ? (
                        ""
                      ) : (
                        <div className="d-flex align-items-center justify-content-center py-2">
                          <BtnBuy data={item} type="section" />
                        </div>
                      )}
                    </div>
                    <div className="text-start mt-2">
                      <span
                        style={{ fontSize: "12px" }}
                        className=" px-2 text-bg-primary rounded-5"
                      >
                        تاريخ انشاء : {handleExpiryTime(item.createdAt)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
