import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { MdDriveFileRenameOutline ,MdOutlinePriceChange} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { FaChalkboardTeacher } from "react-icons/fa";
import css from "../MainStyleForms.module.css";
import { IoAdd } from "react-icons/io5";
import { sectionFormValid } from "../../../Util/VallationForms";
import { getAllClass } from "../../../Store/ClassSlice";
import { createSection, editSection } from "../../../Store/SectionSlice";
import AlertsModel from "../../../Model/AlertsModel/AlertsModel";
import { RiDiscountPercentFill } from "react-icons/ri";




export default function SectionForm({ type, SendData }) {
  const { loading } = useSelector((state) => state.section);
  const classRedux = useSelector((state) => state.class);
  const [img, setImg] = useState(SendData?.image || null);

  const dispatch = useDispatch();

  useEffect(() => {
    try {
      dispatch(getAllClass());
    } catch (err) {
      console.error(err);
    }
  }, [dispatch]);

  const formik = useFormik({
    initialValues: {
      name: SendData?.name || "",
      image: SendData?.image || null,
      class: SendData?.class?._id || "",
      price: SendData?.price || "",
      description: SendData?.description || "",
      discount: SendData?.discount || "",
    },

    validationSchema: sectionFormValid,
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("image", values.image);
      formData.append("class", values.class);
      formData.append("price", values.price);
      formData.append("description", values.description);
      formData.append("discount", values.discount);

      try {
        if (type === "edit") {
          if (typeof values.image === "object") {
            dispatch(editSection([SendData._id, formData]));
          } else if (typeof values.image === "string") {
            formData.delete("image");
            dispatch(editSection([SendData._id, formData]));
          }
        } else if (type === "add") {
          dispatch(createSection(formData));
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const fileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imgs = URL.createObjectURL(file);
      setImg(imgs);
      formik.setFieldValue("image", file);
    }
  };
  return (
    <>
      <div className={`container ${css.container} `}>
        <h5
          style={{
            color: "var(--dark-color)",
            textShadow: "var(--main-color) 0px 0px 10px",
          }}
          className="text-center py-1"
        >{`${type === "add" ? "اضف" : "عدل"} الباب`}</h5>
        <form className="row g-3" onSubmit={formik.handleSubmit}>
          <div className="col-12 d-flex align-items-center justify-content-center  flex-column">
            <div className={css.image}>
              {img && <img src={img} alt="MyPhoto" loading="lazy"  />}
              <div>
                <label className={css.customFileInput} htmlFor="image">
                  <IoAdd />
                </label>
                <input
                  type="file"
                  id="image"
                  name="image"
                  accept=".png, .jpg, .jpeg"
                  onChange={fileChange}
                />
                {formik.touched.image && formik.errors.image && (
                  <div className="text-danger">{formik.errors.image}</div>
                )}
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="input-group has-validation mb-2">
              <span className="input-group-text rounded-0 rounded-end">
                <MdDriveFileRenameOutline
                  className="fs-4"
                  style={{ color: "var(--main-color)" }}
                />
              </span>
              <div className="form-floating is-invalid ">
                <input
                  type="text"
                  className={`form-control rounded-0 rounded-start ${
                    formik.touched.name && formik.errors.name && "is-invalid"
                  }`}
                  id="name"
                  placeholder=""
                  {...formik.getFieldProps("name")}
                />
                <label
                  style={{ color: "var(--gray-color)" }}
                  htmlFor="name right-0"
                >
                  ادخل اسم الباب
                </label>
              </div>
              {formik.touched.name && formik.errors.name && (
                <div className="invalid-feedback">{formik.errors.name}</div>
              )}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="input-group has-validation mb-2">
              <span className="input-group-text rounded-0 rounded-end">
                <MdOutlinePriceChange
                  className="fs-4"
                  style={{ color: "var(--main-color)" }}
                />
              </span>
              <div className="form-floating is-invalid ">
                <input
                  type="number"
                  className={`form-control rounded-0 rounded-start ${
                    formik.touched.price && formik.errors.price && "is-invalid"
                  }`}
                  id="price"
                  placeholder=""
                  {...formik.getFieldProps("price")}
                />
                <label
                  style={{ color: "var(--gray-color)" }}
                  htmlFor="price right-0"
                >
                  ادخل السعر
                </label>
              </div>
              {formik.touched.price && formik.errors.price && (
                <div className="invalid-feedback">{formik.errors.price}</div>
              )}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="input-group has-validation mb-2">
              <span className="input-group-text rounded-0 rounded-end">
                <RiDiscountPercentFill
                  className="fs-4"
                  style={{ color: "var(--main-color)" }}
                />
              </span>
              <div className="form-floating is-invalid ">
                <input
                  type="number"
                  className={`form-control rounded-0 rounded-start ${
                    formik.touched.price && formik.errors.price && "is-invalid"
                  }`}
                  id="discount"
                  placeholder=""
                  {...formik.getFieldProps("discount")}
                />
                <label
                  style={{ color: "var(--gray-color)" }}
                  htmlFor="discount right-0"
                >
                  ادخل الخصم
                </label>
              </div>
              {formik.touched.discount && formik.errors.discount && (
                <div className="invalid-feedback">{formik.errors.discount}</div>
              )}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="input-group has-validation col-md-12">
              <span className="input-group-text rounded-0 rounded-end">
                {classRedux.loading ? (
                  <span
                    className="spinner-border spinner-border-sm mx-2"
                    aria-hidden="true"
                  ></span>
                ) : (
                  <FaChalkboardTeacher
                    className="fs-5"
                    style={{ color: "var(--main-color)" }}
                  />
                )}
              </span>
              <div className="form-floating is-invalid">
                <select
                  id="class"
                  style={{ color: "var(--gray-color)" }}
                  className={`form-select form-select-md rounded-0 rounded-start ${
                    formik.touched.class && formik.errors.class && "is-invalid"
                  }`}
                  aria-label="Large select example"
                  {...formik.getFieldProps("class")}
                  value={formik.values.class}
                >
                  <option value="">اختر الصف</option>
                  {classRedux?.records?.data?.map((e) => (
                    <option
                      style={{ fontSize: "14px" }}
                      key={e?._id}
                      value={e?._id}
                    >
                      {`صف = (${e.name}) - مستر = (${e?.teacher?.name})`}
                    </option>
                  ))}
                </select>
              </div>
              {formik.touched.class && formik.errors.class && (
                <div className="invalid-feedback ">{formik.errors.class}</div>
              )}
            </div>
          </div>
          <div className="col-12 mb-3">
            <div className="form-floating has-validation">
              <textarea
                className={`form-control ${
                  formik.touched.description &&
                  formik.errors.description &&
                  "is-invalid"
                }`}
                placeholder="Leave a comment here"
                id="description"
                style={{ height: "100px" }}
                {...formik.getFieldProps("description")}
              ></textarea>
              <label
                style={{ color: "var(--gray-color)" }}
                htmlFor="description"
              >
                اكتب نبذة عن الباب
              </label>
            </div>
            {formik.touched.description && formik.errors.description && (
              <div className="text-danger" style={{ fontSize: "12px" }}>
                {formik.errors.description}.
              </div>
            )}
          </div>
          <div className="col-12  d-flex justify-content-center align-content-center">
            <button
              type="submit"
              disabled={loading}
              className="btn btn-primary px-4 py-2 fs-5"
            >
              {!loading ? (type === "add" ? "اضافة" : "تعديل") : "جاري الارسال"}
              {loading && (
                <span
                  className="spinner-border spinner-border-sm mx-2"
                  aria-hidden="true"
                ></span>
              )}
            </button>
          </div>
        </form>
      </div>
      {classRedux?.error && (
        <AlertsModel
          error={true}
          msg="هناك خطاء في احضار الفصول"
          place="الباب"
        />
      )}
    </>
  );
}
