import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllTeachers, resetTeacherData } from "../../../Store/TeacherSlice";
import { RiAdminFill } from "react-icons/ri";
// import { IoCloseSharp } from "react-icons/io5";

export default function FilterDataForTeacher({
  setLectureData,
  setSectionsData,
  sectionsData,
  lectureData,
}) {
  const { records, loading } = useSelector((state) => state.teacher);
  const dispatch = useDispatch();
  const selector = useRef();

  useEffect(() => {
    dispatch(getAllTeachers());
    return () => {
      dispatch(resetTeacherData());
    };
  }, [dispatch]);

  const handelresetData = () => {
    if (setLectureData && lectureData) {
      setLectureData(lectureData);
    }
    if (setSectionsData && sectionsData) {
      setSectionsData(sectionsData);
    }
    selector.current.value = "All";
  };

  const handleTeacherChange = (event) => {
    const value = event.target.value;
    if (setLectureData && lectureData) {
      const lecture = lectureData.filter(
        (e) => e?.section?.class?.teacher?._id === value
      );
      setLectureData(lecture);
    }
    if (setSectionsData && sectionsData) {
      const sections = sectionsData.filter(
        (e) => e?.class?.teacher?._id === value
      );
      setSectionsData(sections);
    }
    if (value === "All") {
      handelresetData();
    }
  };

  return (
    <div className="d-flex flex-column">
      <div className="row gx-4">
        <div className="col-12">
          <div className="input-group has-validation flex-grow-1 ms-md-2">
            <span className="input-group-text rounded-0 rounded-end">
              {loading ? (
                <span
                  className="spinner-border spinner-border-sm me-1"
                  aria-hidden="true"
                ></span>
              ) : (
                <RiAdminFill
                  className="fs-5"
                  style={{ color: "var(--main-color)" }}
                />
              )}
            </span>
            <div className="form-floating is-invalid">
              <select
                ref={selector}
                style={{ color: "var(--gray-color)" }}
                id="id"
                className={`form-select form-select-md rounded-0 rounded-start`}
                aria-label="Large select example"
                onChange={handleTeacherChange}
              >
                <option value="All">جميع المدرسين </option>
                {records?.data?.map((teacher, i) => (
                  <option key={i} value={teacher?._id}>
                    {teacher.name}
                  </option>
                ))}
              </select>
            </div>
            {/* <span
              onClick={handelresetData}
              className="input-group-text rounded-0 rounded-start bg-danger"
              style={{ cursor: "pointer" }}
            >
              <IoCloseSharp className="fs-5 text-light" />
            </span> */}
          </div>
        </div>
      </div>
    </div>
  );
}
