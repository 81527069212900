import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { axiosInstance } from "./Config";
import { setError } from "./ErrorSlice";

const initState = {
  records: [],
  loading: false,
  error: false,
  complete: false,
};

const access_token = () => Cookies.get("access_token");

export const getMe = createAsyncThunk("me/getMe", async (_, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;
  try {
    const response = await axiosInstance.get(`/users/getMe`, {
      headers: {
        Authorization: `Bearer ${access_token()}`,
      },
    });
    return response.data;
  } catch (error) {
    dispatch(
      setError({
        type: "error",
        message: "حدث خطاء ما في جلب   بياناتي ",
        location: "بياناتي",
      })
    );
      Cookies.remove("access_token");
    return rejectWithValue(error);
  }
});

const getMeSlice = createSlice({
  name: "me",
  initialState: initState,
  reducers: {
    resetData(state) {
      state.error = false;
      state.complete = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // getMe
      .addCase(getMe.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.complete = false;
      })
      .addCase(getMe.fulfilled, (state, action) => {
        state.loading = false;
        state.records = action.payload.data;
        state.complete = true;
      })
      .addCase(getMe.rejected, (state, action) => {
        state.loading = false;
        state.error = `Error`;
        state.complete = false;
      });
  },
});

export const { resetData } = getMeSlice.actions;

export default getMeSlice.reducer;
