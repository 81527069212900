import css from "./TeacherDetails.module.css";

export default function TeacherDetails({ data }) {
  const sectionFilter = (id) => {
    const filter = data?.section?.filter((e) => e.class?._id === id);
    return filter
  };
  const lectureFilter = (id) => {
    const filter = data?.lecutre?.filter((e) => e.section?._id === id);
    return filter
  };
  return (
    <>
      {data?.classes && (
        <div className={`${css.container}`}>
          {data?.classes?.map((item, i) => (
            <div className={`px-3 px-md-5 ${css.classContainer}`} key={i}>
              <h3 className={` text-center ${css.classTitle}`}>{item?.name}</h3>
              <ol type="a" className="row g-3 p-2 align-items-start">
                {sectionFilter(item?._id).length >= 1
                  ? sectionFilter(item?._id).map((e, y) => (
                      <div
                        className="col-12 col-md-6 col-lg-4 d-flex pe-4 justify-content-center flex-column"
                        key={y}
                      >
                        <li className={css.sectionTitle}>{e.name}</li>
                        <ol>
                          {lectureFilter(e?._id).map((lecture, z) => (
                            <li className={css.lecutreTitle} key={z}>
                              {lecture.lecture}
                            </li>
                          ))}
                        </ol>
                      </div>
                    ))
                  : "لا يوجد ابواب في هذا الصف "}
              </ol>
            </div>
          ))}
        </div>
      )}
    </>
  );
}
