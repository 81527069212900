import React, { useEffect, useState } from "react";
import { MdDriveFileRenameOutline, MdOutlinePriceChange } from "react-icons/md";
import { FaChalkboardTeacher } from "react-icons/fa";
import css from "../MainStyleForms.module.css";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { getAllSections } from "../../../Store/SectionSlice.js";
import { createLecture, editLecture } from "../../../Store/LectureSlice.js";
import { FaFilePdf } from "react-icons/fa6";
import { LectureFormValid } from "../../../Util/VallationForms.js";
import FilterDataForTeacher from "../../Serches/FilterDataForTeacher/FilterDataForTeacher.jsx";

export default function LectureForm({ type, SendData }) {
  const { loading } = useSelector((state) => state.lecture);
  const sectionRedux = useSelector((state) => state.section);

  const [, setPdf] = useState(SendData?.pdf || null);
  const [sectionsData, setSectionsData] = useState(sectionRedux.records.data);

  const dispatch = useDispatch();
  useEffect(() => {
    try {
      dispatch(getAllSections());
    } catch (err) {
      console.error(err);
    }
  }, [dispatch]);

  const formik = useFormik({
    initialValues: {
      lecture: SendData?.lecture || "",
      section: SendData?.section?._id || "",
      price: SendData?.price || "",
      pdf: SendData?.pdf || "",
      description: SendData?.description || "",
    },

    validationSchema: LectureFormValid,
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("lecture", values.lecture);
      formData.append("section", values.section);
      formData.append("price", values.price);
      formData.append("pdf", values.pdf);
      formData.append("description", values.description);

      try {
        if (type === "edit") {
          if (typeof values.pdf === "object") {
            dispatch(editLecture([SendData._id, formData]));
          } else if (typeof values.pdf === "string") {
            formData.delete("pdf");
            dispatch(editLecture([SendData._id, formData]));
          }
        } else if (type === "add") {
          dispatch(createLecture(formData));
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });
  const pdfChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const pdfs = URL.createObjectURL(file);
      setPdf(pdfs);
      formik.setFieldValue("pdf", file);
    }
  };

  return (
    <>
      <div className={`container ${css.container} `}>
        <h5
          style={{
            color: "var(--dark-color)",
            textShadow: "var(--main-color) 0px 0px 10px",
          }}
          className="text-center py-1"
        >{`${type === "add" ? "اضف" : "عدل"} المحاضره`}</h5>
        <form className="row g-3" onSubmit={formik.handleSubmit}>
          <div className="text-center text-danger">
            <span>فلتير بناء علي المدرس</span>
          </div>
          <FilterDataForTeacher
            setSectionsData={setSectionsData}
            sectionsData={sectionRedux.records.data}
          />
          <div className="col-12 col-md-6">
            <div className="input-group has-validation mb-2">
              <span className="input-group-text rounded-0 rounded-end">
                <MdDriveFileRenameOutline
                  className="fs-4"
                  style={{ color: "var(--main-color)" }}
                />
              </span>
              <div className="form-floating is-invalid ">
                <input
                  type="text"
                  className={`form-control rounded-0 rounded-start ${
                    formik.touched.lecture &&
                    formik.errors.lecture &&
                    "is-invalid"
                  }`}
                  id="lecture"
                  placeholder=""
                  {...formik.getFieldProps("lecture")}
                />
                <label
                  style={{ color: "var(--gray-color)" }}
                  htmlFor="name right-0"
                >
                  ادخل اسم المحاضره
                </label>
              </div>
              {formik.touched.lecture && formik.errors.lecture && (
                <div className="invalid-feedback">{formik.errors.lecture}</div>
              )}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="input-group has-validation mb-2">
              <span className="input-group-text rounded-0 rounded-end">
                <MdOutlinePriceChange
                  className="fs-4"
                  style={{ color: "var(--main-color)" }}
                />
              </span>
              <div className="form-floating is-invalid ">
                <input
                  type="number"
                  className={`form-control rounded-0 rounded-start ${
                    formik.touched.price && formik.errors.price && "is-invalid"
                  }`}
                  id="price"
                  placeholder=""
                  {...formik.getFieldProps("price")}
                />
                <label
                  style={{ color: "var(--gray-color)" }}
                  htmlFor="price right-0"
                >
                  ادخل السعر
                </label>
              </div>
              {formik.touched.price && formik.errors.price && (
                <div className="invalid-feedback">{formik.errors.price}</div>
              )}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="input-group has-validation mb-2">
              <span className="input-group-text rounded-0 rounded-end">
                {sectionRedux.loading ? (
                  <span
                    className="spinner-border spinner-border-sm mx-2"
                    aria-hidden="true"
                  ></span>
                ) : (
                  <FaChalkboardTeacher
                    className="fs-5"
                    style={{ color: "var(--main-color)" }}
                  />
                )}
              </span>
              <div className="form-floating is-invalid">
                <select
                  id="section"
                  style={{ color: "var(--gray-color)" }}
                  className={`form-select form-select-md rounded-0 rounded-start ${
                    formik.touched.section &&
                    formik.errors.section &&
                    "is-invalid"
                  }`}
                  aria-label="Large select example"
                  {...formik.getFieldProps("section")}
                  value={formik.values.section}
                >
                  <option value="">اختر الباب</option>
                  {sectionsData?.map((e) => (
                    <option
                      style={{ fontSize: "14px" }}
                      key={e?._id}
                      value={e?._id}
                    >
                      {`باب = ( ${e.name} ) - مستر ( ${e?.class.teacher?.name} )`}
                    </option>
                  ))}
                </select>
              </div>
              {formik.touched.section && formik.errors.section && (
                <div className="invalid-feedback ">{formik.errors.section}</div>
              )}
            </div>
          </div>
          <div className={`col-12 col-md-6 ${css.pdf}`}>
            <div className="input-group has-validation mb-2">
              <span className="input-group-text rounded-0 rounded-end">
                <FaFilePdf
                  className="fs-4"
                  style={{ color: "var(--main-color)" }}
                />
              </span>
              <div className="form-floating is-invalid ">
                <input
                  dir="ltr"
                  type="file"
                  className={`form-control rounded-0 rounded-start ${
                    formik.touched.pdf && formik.errors.pdf && "is-invalid"
                  }`}
                  id="pdf"
                  placeholder=""
                  accept=".pdf"
                  onChange={pdfChange}
                />
                <label
                  style={{ color: "var(--gray-color)" }}
                  htmlFor="pdf right-0"
                >
                  ادخل pdf
                </label>
              </div>
              {formik.touched.pdf && formik.errors.pdf && (
                <div className="invalid-feedback">{formik.errors.pdf}</div>
              )}
            </div>
          </div>
          <div className="col-12 mb-3">
            <div className="form-floating has-validation">
              <textarea
                className={`form-control ${
                  formik.touched.description &&
                  formik.errors.description &&
                  "is-invalid"
                }`}
                placeholder="Leave a comment here"
                id="description"
                style={{ height: "100px" }}
                {...formik.getFieldProps("description")}
              ></textarea>
              <label
                style={{ color: "var(--gray-color)" }}
                htmlFor="description"
              >
                اكتب نبذة عن المحاضره
              </label>
            </div>
            {formik.touched.description && formik.errors.description && (
              <div className="text-danger" style={{ fontSize: "12px" }}>
                {formik.errors.description}.
              </div>
            )}
          </div>

          <div className="col-12  d-flex justify-content-center align-content-center">
            <button
              type="submit"
              disabled={loading}
              className="btn btn-primary px-4 py-2 fs-5"
            >
              {!loading ? (type === "add" ? "اضافة" : "تعديل") : "جاري الارسال"}
              {loading && (
                <span
                  className="spinner-border spinner-border-sm mx-2"
                  aria-hidden="true"
                ></span>
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
